import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          Copyright © Designed &amp; Developed by <a>PayBagApp</a> 2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
