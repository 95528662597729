import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { addCommissionApi } from "../../services/Vendor Services/VendorService";

const AddCommission = ({ show, handleClose, vendorData, refresh }) => {
  const [price, setPrice] = useState(vendorData?.commission);
  const [priceError, setPriceError] = useState("");
  const [loader, setLoader] = useState(false);

  console.log(vendorData);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (price.trim() === "") {
      setPriceError("Commission is required");
      return;
    } else if (price.trim() < 0) {
      setPriceError("Commission can't be negative");
      return;
    } else if (price.trim() > 100) {
      setPriceError("Commission can't be greater than 100%");
      return;
    }
    setLoader(true);
    try {
      const response = await addCommissionApi(price, vendorData?.id);
      toast.success("Add Commission successfully");
      handleClose();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Set Vendor Commission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPrice">
            <Form.Label>Percentage</Form.Label>
            <Form.Control
              type="number"
              placeholder="%"
              className="form-control"
              value={price}
              onChange={(e) => {
                setPrice(e.target.value);
                setPriceError("");
              }}
              required
            />
            {priceError && <span className="text-danger">{priceError}</span>}
          </Form.Group>
          <button className="btn-sm w-100 btn btn-primary">
            {loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Set Commission"
            )}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCommission;
