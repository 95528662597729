import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col, Button } from "react-bootstrap";
import Pagination from "../common/Pagination";
import PageTitle from "../layouts/PageTitle";
import UserTableSkeleton from "../components/UserTableSkeleton";
import defaultImage from "../../images/emptyImg.jpg";
import {
  addBrandApi,
  getBrandListApi,
  actionBrandApi,
  editBrandApi,
} from "../../services/Vendor Services/VendorService";
import AddBrandModal from "../modal/AddBrandModal";
import toast from "react-hot-toast";
import moment from "moment";

export default function BrandManagement() {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [addBrand, setAddBrand] = useState(false);
  const [editBrand, setEditBrand] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedBrand, setSelectedBrand] = useState("");
  const limit = 10;

  function getTableData() {
    setLoader(true);
    getBrandListApi(currentPage, limit)
      .then((response) => {
        setData(response.data.data);

        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  function handleError(e) {
    e.target.src = defaultImage;
  }
  async function handleToggleBrand(id, status) {
    console.log(id);
    setLoader(true);
    try {
      const response = await actionBrandApi(id, status);
      toast.success(response.data.data);
      getTableData();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center pb-3 pl-3 pr-3">
          <h3 className="m-0">Brand Management</h3>
          <div className="">
            <button
              className="btn btn-sm btn-primary"
              onClick={() => setAddBrand(true)}
            >
              Add brand
            </button>
          </div>
        </div>
        <Col>
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>IMAGE</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>title</strong>
                    </th>
                    <th>
                      <strong>link</strong>
                    </th>
                    <th>
                      <strong>created At</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                {loader ? (
                  <UserTableSkeleton count={8} />
                ) : (
                  <tbody>
                    {data?.brands?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <img
                            src={`https://api.paybagapp.com/${item?.image}`}
                            width={60}
                            height={60}
                            style={{ borderRadius: "8px" }}
                            onError={handleError}
                          />
                        </td>
                        <td>
                          {item?.name?.length > 20
                            ? item?.name?.slice(0, 20) + "..."
                            : item?.name}
                        </td>
                        <td>
                          {item?.title?.length > 20
                            ? item?.title?.slice(0, 20) + "..."
                            : item?.title}
                        </td>
                        <td>
                          {item?.link?.length > 35
                            ? item?.link?.slice(0, 35) + "..."
                            : item?.link}
                        </td>
                        <td style={{ minWidth: "150px" }}>
                          {moment(item.createdAt).format("ll")}{" "}
                        </td>
                        <td className="text-center">
                          {item.status ? (
                            <Badge variant="success light">Enable</Badge>
                          ) : (
                            <Badge variant="danger light">Disable</Badge>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setEditBrand(true);
                                  setSelectedBrand(item);
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              {item?.status ? (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleToggleBrand(item.id, "Disable")
                                  }
                                >
                                  Disable
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    handleToggleBrand(item.id, "Enable")
                                  }
                                >
                                  Enable
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              {data?.total === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div className="dataTables_info">
                  Total Brands
                  <span
                    style={{
                      color: "#042e77",
                      border: "1px solid #042e77",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.total}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
      {addBrand && (
        <AddBrandModal
          show={addBrand}
          onHide={() => setAddBrand(false)}
          title={"Add Brand"}
          successMessage={"Brand added successfully"}
          api={addBrandApi}
          refresh={getTableData}
        />
      )}
      {editBrand && (
        <AddBrandModal
          show={editBrand}
          onHide={() => setEditBrand(false)}
          title={"Edit Brand"}
          successMessage={"Brand Updated successfully"}
          api={editBrandApi}
          refresh={getTableData}
          selectedBrand={selectedBrand}
        />
      )}
    </div>
  );
}
