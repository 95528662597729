import React, { useState } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { deductUserPointApi } from "../../services/User/UserService";

export default function DeductUserPointsModal({
  userData,
  show,
  onHide,
  refresh,
}) {
  const [points, setPoints] = useState(userData?.generalPoints);
  const [reason, setReason] = useState(userData?.reason);
  const [pointsError, setPointsError] = useState("");
  const [reasonError, setReasonError] = useState("");

  const [loader, setLoader] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const pointsValue = parseFloat(points);

    if (isNaN(pointsValue) || points.trim() === "") {
      setPointsError("Points is required");
      return;
    } else if (pointsValue <= 0) {
      setPointsError("Points must be greater than zero");
      return;
    }
    if (reason.trim() === "") {
      setReasonError("Reason can't be empty");
    }

    setLoader(true);
    try {
      const postData = {
        point: pointsValue,
        userId: userData?.id,
        description: reason,
      };
      const response = await deductUserPointApi(postData);
      toast.success("Points deducted successfully");
      onHide();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Deduct User Points</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formPoints">
            <Form.Label>Points</Form.Label>
            <Form.Control
              type="number"
              placeholder="Enter the points"
              className="form-control"
              value={points}
              onChange={(e) => {
                setPoints(e.target.value);
                setPointsError("");
              }}
              required
              step="any"
            />
            {pointsError && <span className="text-danger">{pointsError}</span>}
          </Form.Group>
          <Form.Group controlId="formReason">
            <Form.Label>Reason</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter the reason"
              className="form-control"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setReasonError("");
              }}
              required
              step="any"
            />
            {reasonError && <span className="text-danger">{reasonError}</span>}
          </Form.Group>
          <button className="btn-sm w-100 btn btn-primary">
            {loader ? <Spinner animation="border" size="sm" /> : "Deduct"}
          </button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
