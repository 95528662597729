import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Dropdown, Table } from "react-bootstrap";
import moment from "moment";
import { getCouponListApi } from "../../services/Vendor Services/VendorService";
import PageTitle from "../layouts/PageTitle";
import AddCoupon from "../modal/AddCoupon";
import {
  addCouponApi,
  deleteCouponApi,
  editCouponApi,
} from "../../services/Vendor Services/VendorService";
import UserTableSkeleton from "../components/UserTableSkeleton";
import toast from "react-hot-toast";
import Pagination from "../common/Pagination";
import ConfirmationModal from "../modal/ConfirmationModal";

export default function CouponList(props) {
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  const [addCouponModal, setAddCouponModal] = useState(false);
  const [editCouponModal, setEditCouponModal] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState("");
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);

  const limit = 10;

  function getTableData() {
    setLoader(true);
    getCouponListApi(currentPage, limit)
      .then((response) => {
        setTableData(response?.data?.data);

        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
      });
  }

  async function deleteBrand(item) {
    setSelectedCoupon(item);
    setDeleteModal(true);
  }

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center pr-3 pl-3 pb-3">
          <h3 className="m-0">Coupon List</h3>
          <div className="">
            <button
              className="btn btn-sm btn-primary"
              onClick={() =>
                props.history.push({
                  pathname: "/add-coupon-form",
                  state: {
                    successMessage: "Added Coupon successfully",
                    title: "Add Coupon",
                  },
                })
              }
            >
              Add Coupon
            </button>
          </div>
        </div>
        <Col>
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Image</strong>
                    </th>
                    <th>
                      <strong>title</strong>
                    </th>
                    <th>
                      <strong>Start Date</strong>
                    </th>
                    <th>
                      <strong>Expire Date</strong>
                    </th>
                    <th className="text-center">
                      <strong>Point Price</strong>
                    </th>
                    <th>
                      <strong>Created At</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th>
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>
                {loader ? (
                  <UserTableSkeleton count={8} />
                ) : (
                  <tbody>
                    {tableData?.coupons?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          <img
                            src={`https://api.paybagapp.com/${item?.logo}`}
                            width={60}
                            height={60}
                            style={{ borderRadius: "20px" }}
                          />
                        </td>
                        <td>{item.title}</td>
                        <td>{moment(item.startDate).format("ll")} </td>

                        <td>{moment(item.validDate).format("ll")} </td>
                        <td className="text-center">{item.pointPrice}</td>
                        <td>{moment(item.createdAt).format("ll")} </td>

                        <td>
                          {" "}
                          {item?.leftAvailableNumber === 0 ? (
                            <Badge
                              variant="danger light"
                              className="d-flex align-items-center "
                            >
                              {"Redeemed"}
                            </Badge>
                          ) : item?.isExpire ? (
                            <Badge
                              variant="danger light"
                              className="d-flex align-items-center"
                            >
                              {"Expired"}
                            </Badge>
                          ) : (
                            <Badge variant="success light">{"Active"}</Badge>
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="info light"
                              className="light sharp btn btn-info i-false"
                            >
                              {svg1}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {!item?.isExpire && (
                                <Dropdown.Item
                                  onClick={() =>
                                    props.history.push({
                                      pathname: "/add-coupon-form",
                                      state: {
                                        selectedCoupon: item,
                                        successMessage:
                                          "Edit Wheel contest successfully",
                                        title: "Edit Coupon",
                                      },
                                    })
                                  }
                                >
                                  Edit
                                </Dropdown.Item>
                              )}
                              <Dropdown.Item onClick={() => deleteBrand(item)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>
              {tableData?.total === 0 && !loader && (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              )}
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div className="dataTables_info">
                  Total Coupons
                  <span
                    style={{
                      color: "#042e77",
                      border: "1px solid #042e77",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {tableData?.total}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </div>
      {addCouponModal && (
        <AddCoupon
          show={addCouponModal}
          onHide={() => setAddCouponModal(false)}
          title={"Add Coupon"}
          successMessage={"Added Coupon Successfully"}
          api={addCouponApi}
          refresh={getTableData}
        />
      )}
      {editCouponModal && (
        <AddCoupon
          show={editCouponModal}
          onHide={() => setEditCouponModal(false)}
          title={"Edit Coupon"}
          successMessage={"Updated Coupon Successfully"}
          api={editCouponApi}
          refresh={getTableData}
          selectedCoupon={selectedCoupon}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          successMessage={"Coupon deleted successfully"}
          api={deleteCouponApi}
          refresh={getTableData}
          content={"Are you sure you want to Delete this Coupon ?"}
          data={selectedCoupon}
        />
      )}
    </div>
  );
}
