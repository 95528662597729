import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, LOGIN, LOGOUT } from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import swal from "sweetalert";

export function signUp(email, password) {
  const postData = {
    email,
    password,
    type: "Vendor",
  };

  return axiosInstance.post(``, postData);
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axiosInstance.post("admin/login", postData);
}

export function dashboard(filter) {
  return axiosInstance.get(DASHBOARD + `?data=${filter}`);
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}
export function logOut() {
  return axiosInstance.post(LOGOUT, { test: "" });
}

export function formatError(errorMessage) {
  if (errorMessage) {
    swal("Oops", errorMessage, "error");
  }
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("loyaltyAdminToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("loyaltyAdminToken");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
