import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import Pagination from "../common/Pagination";
import {
  getApproveVendorApi,
  venderActionApi,
  deleteVenderApi,
  venderApproveApi,
  venderPremiumApi,
} from "../../services/Vendor Services/VendorService";
import defaultImage from "../../images/emptyImg.jpg";
import UserTableSkeleton from "../components/UserTableSkeleton";
import Switch from "react-switch";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import RejectVendorMessageModal from "../modal/RejectVendorMessageModal";
import AddCommission from "../modal/AddCommission";
import PageTitle from "../layouts/PageTitle";
import { gerExportFileApi } from "../../services/User/UserService";

export default function VendorManagement(props) {
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [addCommissionModal, setAddCommissionModal] = useState(false);
  const [data, setData] = useState();
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");

  const limit = 10;

  function handleError(e) {
    e.target.src = defaultImage;
  }
  function getTableData() {
    setLoader(true);
    getApproveVendorApi(currentPage, limit, search)
      .then((response) => {
        setList(response.data.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 500),
    []
  );

  async function getExportFileUrl() {
    setIsLoading(true);
    try {
      const response = await gerExportFileApi("Vendor");
      setFileUrl(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "error");
      setIsLoading(false);
    }
  }
  const downloadFile = () => {
    if (fileUrl) {
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  async function deleteVender(item) {
    setDeleteModal(true);
    setData(item);
  }

  const blockUnBlockVender = async (id) => {
    setLoader(true);
    try {
      const response = await venderActionApi(id);
      const message = response.data.data;
      getTableData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  async function handlePremiumVender(id) {
    setLoader(true);
    try {
      const response = await venderPremiumApi(id);
      toast.success("Granted successfully");
      getTableData();
    } catch (error) {
      toast.error(error?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  function onSendVenderItem(item) {
    props.history.push({
      pathname: "./vender-details",
      state: item,
    });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);

  return (
    <div>
      <div>
        <h3 className="pl-3 pb-3">Vendor Management</h3>
      </div>
      <div className="d-flex justify-content-between align-items-center pl-3 pr-3">
        <div className="form-group" style={{ width: "35%" }}>
          <input
            type="text"
            name="table_search"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div className="">
          {fileUrl ? (
            <button className="btn btn-primary btn-sm" onClick={downloadFile}>
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Download file"
              )}
            </button>
          ) : (
            <button
              className="btn btn-primary btn-sm"
              onClick={getExportFileUrl}
            >
              {isLoading ? (
                <Spinner animation="border" size="sm" />
              ) : (
                "Export file"
              )}
            </button>
          )}
        </div>
      </div>
      <Col>
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th className="text-center">
                      <strong>Commission</strong>
                    </th>
                    <th className="text-center">
                      <strong>Premium access</strong>
                    </th>
                    <th>
                      <strong>STATUS</strong>
                    </th>

                    <th className="text-center">
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {list?.vendors?.map((item, i) => (
                    <tr key={i}>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item?.name}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item?.email}
                      </td>
                      <td className="text-center">
                        {item?.commission + "%" || "N/A"}
                      </td>
                      <td>
                        <label className="m-0 d-flex align-items-center justify-content-center">
                          <Switch
                            onChange={() => handlePremiumVender(item.id)}
                            checked={item.isPremium}
                            onColor={"#13315C"}
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          />
                        </label>
                      </td>

                      {item?.verifyStatus === "Accepted" && (
                        <td>
                          <label className="m-0 d-flex align-items-center">
                            <Switch
                              onChange={() => blockUnBlockVender(item.id)}
                              checked={item.isBlocked}
                              onColor={"#FF0000"}
                              handleDiameter={30}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            />
                          </label>
                        </td>
                      )}

                      <td>
                        <div
                          className="d-flex justify-content-center "
                          style={{ gap: "7px" }}
                        >
                          <div
                            class="btn btn-info  btn-xs sharp list-btn"
                            onClick={() => {
                              setAddCommissionModal(true);
                              setData(item);
                            }}
                          >
                            <i class="fa fa-money"></i>
                          </div>
                          <div
                            class="btn btn-danger  btn-xs sharp list-btn"
                            style={{
                              cursor: "pointer",
                              backgroundColor: "red",
                              color: "white",
                            }}
                            onClick={() => deleteVender(item)}
                          >
                            <i class="fa fa-trash"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {list?.total === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Vendor
                  <span
                    style={{
                      color: "#042e77",
                      border: "1px solid #042e77",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {list?.total}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
      {deleteModal && (
        <ConfirmationModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          successMessage={"Deleted Vendor successfully"}
          api={deleteVenderApi}
          refresh={getTableData}
          content={"Are you sure you want to Delete this Vendor ?"}
          data={data}
        />
      )}
      {rejectModal && (
        <RejectVendorMessageModal
          show={rejectModal}
          onHide={() => setRejectModal(false)}
          data={data}
          refresh={getTableData}
        />
      )}
      {addCommissionModal && (
        <AddCommission
          show={addCommissionModal}
          handleClose={() => setAddCommissionModal(false)}
          vendorData={data}
          refresh={getTableData}
        />
      )}
    </div>
  );
}
