import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

/// Dashboard
import Home from "./components/Dashboard/Home";

/// App

/// Product List

/// Charts

/// Bootstrap

/// Table

/// Form

/// Pages

// import SignUp from "./pages/SignUp";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import UserManagement from "./pages/UserManagement";
import UserDetails from "./pages/UserDetails";
import VendorManagement from "./pages/VendorManagement";
import VendorDetails from "./pages/VendorDetails";
import BrandManagement from "./pages/BrandManagement";
import SpinWheel from "./pages/SpinWheel";
import CouponList from "./pages/CouponList";
import SpinWheelHistory from "./pages/SpinWheelHistory";
import Terms from "./pages/Terms";
import Policies from "./pages/Policy";
import SpinWheelDetails from "./pages/SpinWheelDetails";
import AddSpinWheelOffers from "./modal/AddSpinWheelOffers";
import AddCoupon from "./modal/AddCoupon";
import PendingVendorList from "./pages/PendingVendorList";
import NotificationManagements from "./pages/NotificationManagement";
import LoyaltyProgramOffer from "./pages/LoyaltyProgramOffer";
import UserPurchasedOffers from "./pages/UserPurchasedOffers";
import ReferenceManagement from "./pages/ReferenceManagement";
import LoyaltyProgramList from "./pages/LoyaltyProgramList";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },

    /// User management
    { url: "user-management", component: UserManagement },
    { url: "user-details", component: UserDetails },
    { url: "vendor-management", component: VendorManagement },
    { url: "vender-details", component: VendorDetails },
    { url: "brandListing", component: BrandManagement },
    { url: "spin-wheel-management", component: SpinWheel },
    { url: "spin-wheel-details", component: SpinWheelDetails },
    { url: "coupon-management", component: CouponList },
    { url: "spin-wheel-history", component: SpinWheelHistory },
    { url: "terms-condition", component: Terms },
    { url: "privacy-policy", component: Policies },
    { url: "add-contest-form", component: AddSpinWheelOffers },
    { url: "add-coupon-form", component: AddCoupon },
    { url: "pending-vendors", component: PendingVendorList },
    { url: "notification-management", component: NotificationManagements },
    { url: "loyalty-offers", component: LoyaltyProgramOffer },
    { url: "user-loyalty-offers", component: UserPurchasedOffers },
    { url: "reference-management", component: ReferenceManagement },
    { url: "loyalty-programs", component: LoyaltyProgramList },

    // { url: "page-signup", component: SignUp },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
