import React from "react";

export default function Filters({
  type,
  FilterOption,
  setFilterType,
  filterType,
}) {
  return (
    <div>
      {type === "tabs" && (
        <div className="filter-tabs">
          {FilterOption.map((option, index) => (
            <p
              onClick={() => setFilterType(option.value)}
              className={`${
                option.value === filterType ? "active-tab" : "tab"
              }`}
            >
              {option.label}
            </p>
          ))}
        </div>
      )}
      {type === "filter" && (
        <div>
          <select
            style={{ textAlign: "start", fontWeight: "bold" }}
            class="d-flex justify-content-start text-secondary border border-secondary btn btn-sm"
            onChange={(e) => {
              setFilterType(e.target.value);
            }}
            // value={value}
          >
            {FilterOption.map((item) => (
              <option className="filter-options" value={item.value} key={item}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}
