import axiosInstance from "../AxiosInstance";
import { BLOCK_USERS, DELETE_USERS } from "../User/UserApiEndPoints";

export function getAllVendorApi(currentPage, limit, search) {
  return axiosInstance.get(
    `admin/vendors?page=${currentPage}&limit=${limit}&search=${""}`
  );
}

export function getApproveVendorApi(currentPage, limit, search) {
  return axiosInstance.get(
    `admin/approvedVendors?page=${currentPage}&limit=${limit}&search=${search}`
  );
}
export function addVendorApi(name, email) {
  const postData = {
    name: name,
    email: email,
  };
  return axiosInstance.post("admin/createVendor", postData);
}

export function venderActionApi(id) {
  const postData = { userId: id };
  return axiosInstance.put(BLOCK_USERS, postData);
}
export function addCommissionApi(commission, id) {
  const postData = { vendorId: id, commission: commission };
  return axiosInstance.put("admin/addCommission", postData);
}

export function venderApproveApi(id, status, message) {
  const postData = { vendorId: id, status: status, region: message };
  return axiosInstance.put("admin/actionOnVendorRequest", postData);
}
export function venderPremiumApi(id) {
  const postData = { id: id };
  return axiosInstance.put("admin/changePremiumStatus", postData);
}
export function deleteVenderApi(id) {
  return axiosInstance.delete(DELETE_USERS + `?userId=${id}`);
}
//=====================Brand API =================

export function getBrandListApi(currentPage, limit) {
  return axiosInstance.get(
    `admin/brandList?page=${currentPage}&limit=${limit}`
  );
}

export function addBrandApi(postData) {
  return axiosInstance.post("admin/createBrand", postData);
}
export function editBrandApi(postData) {
  return axiosInstance.put("admin/editBrand", postData);
}
export function actionBrandApi(id, status) {
  const data = {
    brandId: id,
    status: status,
  };
  return axiosInstance.put(`admin/actionOnBrand`, data);
}

///===========================Spin Wheel API =================

export function getWheelOfferListApi(currentPage, limit) {
  return axiosInstance.get(`admin/contest?page=${currentPage}&limit=${limit}`);
}
export function getVendorDetailsApi(id) {
  return axiosInstance.get(`admin/vednorDetail?id=${id}`);
}

export function getWheelHistoryApi(id, page, limit) {
  return axiosInstance.get(
    `admin/contestHistory?page=${page}&limit=${limit}&id=${id}`
  );
}
export function getLoyaltyOffersApi(storeId, programId, page, limit) {
  return axiosInstance.get(
    `admin/offers?page=${page}&limit=${limit}&storeId=${storeId}&programId=${programId}`
  );
}

export function addSpinWheelApi(postData) {
  return axiosInstance.post("admin/createWheelContest", postData);
}

export function addWheelConditionApi(postData) {
  return axiosInstance.post("admin/createWheelConditions", postData);
}
export function editWheelOfferApi(postData) {
  return axiosInstance.put("admin/editWheelContest", postData);
}
export function deleteWheelApi(id) {
  return axiosInstance.delete(`admin/deleteContest?id=${id}`);
}

//=======================Coupon API =================
export function getStoreApi(currentPage, limit) {
  return axiosInstance.get("admin/getStore" + `?page=${"0"}&limit=${"100"}`);
}
export function getCouponListApi(currentPage, limit) {
  return axiosInstance.get(
    `admin/couponList?limit=${limit}&page=${currentPage}`
  );
}
export function addCouponApi(postData) {
  return axiosInstance.post("admin/createCoupon", postData);
}

export function editCouponApi(postData) {
  return axiosInstance.put("admin/editCoupon", postData);
}

export function deleteCouponApi(id) {
  return axiosInstance.delete(`admin/deleteCoupon?couponId=${id}`);
}
