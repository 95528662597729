import { useState, useEffect } from "react";
import axiosInstance from "../../../services/AxiosInstance";
import toast from "react-hot-toast";

const useFetch = (url, id, page, limit) => {
  console.log(page);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let fullUrl = url;
        if (id && page !== undefined && page !== null) {
          fullUrl += `id=${id}&page=${page}&limit=${limit}`;
        } else if (id) {
          fullUrl += `id=${id}`;
        } else if (page !== undefined && page !== null) {
          fullUrl += `page=${page}&limit=${limit}`;
        }

        const response = await axiosInstance.get(fullUrl);
        setData(response?.data?.data);
      } catch (err) {
        setError(err);
        toast.error(err?.response?.data?.data);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [url, id, page]);

  return { data, loading, error };
};

export default useFetch;
