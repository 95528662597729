import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  Dropdown,
  Spinner,
  Table,
  Tabs,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import Pagination from "../common/Pagination";
import {
  getCouponPurchasedApi,
  getPointHistoryApi,
  pointStatusApi,
} from "../../services/User/UserService";
import toast from "react-hot-toast";
import useFetch from "../components/CoustomHooks/useFetch";
import { GET_USER_PROGRAM } from "../../services/User/UserApiEndPoints";
import defaultImage from "../../images/emptyImg.jpg";
import UserTableSkeleton from "../components/UserTableSkeleton";

function UserDetails(props) {
  const userData = props?.location?.state;
  const [loader, setLoader] = useState(false);
  const [pointHistory, setPointHistory] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [couponPageCount, setCouponPageCount] = useState(0);
  const [currentCouponPage, setCouponCurrentPage] = useState(0);
  const [couponData, setCouponData] = useState([]);
  const { data, loading, error } = useFetch(
    GET_USER_PROGRAM,
    userData?.id,
    0,
    1000
  );

  function handleError(e) {
    e.target.src = defaultImage;
  }

  function getCouponList() {
    setLoader(true);
    getCouponPurchasedApi(currentCouponPage, limit, userData?.id)
      .then((response) => {
        setCouponData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.total;
        setCouponPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  function onAction(id) {
    setLoader(true);
    pointStatusApi(id)
      .then((response) => {
        getTableData();
        setLoader(false);
        console.log(response);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error.response.data.data);
      });
  }

  function getTableData() {
    setLoader(true);
    getPointHistoryApi(currentPage, limit, userData?.id)
      .then((response) => {
        setPointHistory(response?.data?.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
    getCouponList();
  }, [userData?.id, currentPage, currentCouponPage]);

  return (
    <div>
      <div className="page-titles">
        <h4>User Details</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/user-management">User Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>User Details</Link>
          </li>
        </ol>
      </div>
      <Card>
        <Card.Header>
          <div className="d-flex mt-1" style={{ gap: "1rem", flexGrow: "1" }}>
            <img
              src={`https://api.paybagapp.com/${userData?.image}`}
              width={130}
              height={125}
              style={{ objectFit: "cover", borderRadius: "10px" }}
              onError={handleError}
            />
            <div className="text-left" style={{ flex: "1" }}>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Name</h5>
                <p className="m-0">{userData?.name}</p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Created At</h5>
                <p style={{ margin: "0" }}>
                  {moment(userData?.createdAt).format("ll")}
                </p>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h5 style={{ margin: "0" }}>Email</h5>
                <p style={{ margin: "0" }}>{userData?.email}</p>
              </div>

              <div className="d-flex justify-content-between align-items-center pb-3">
                <h5 className="m-0">Phone</h5>
                <p className="m-0">
                  <span>+{userData?.countryCode} </span>
                  {userData?.phoneNumber || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </Card.Header>

        <Card.Body className="pt-3">
          <Tabs
            defaultActiveKey="loyaltyProgram"
            id="user-details-tabs"
            className="pb-3"
          >
            <Tab eventKey="loyaltyProgram" title="Loyalty Programs">
              <div className="text-black">
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>Image</strong>
                      </th>
                      <th>
                        <strong>Store Name</strong>
                      </th>
                      <th>
                        <strong>program Name</strong>
                      </th>
                      <th>
                        <strong>program title</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>createdAt</strong>
                      </th>
                      <th>
                        <strong>action</strong>
                      </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserTableSkeleton count={8} />
                  ) : (
                    <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                      {data?.programs?.map((item, i) => (
                        <tr
                          key={i}
                          style={{ background: i % 2 !== 0 ? "#e0e6fb" : "" }}
                        >
                          <td>
                            {" "}
                            <img
                              src={`https://api.paybagapp.com/${item?.store?.image}`}
                              width={60}
                              height={60}
                              style={{ borderRadius: "20px" }}
                            />
                          </td>

                          <td>{item?.store?.storeName} </td>
                          <td>{item?.loyaltyProgram?.name} </td>
                          <td>{item?.loyaltyProgram?.title} </td>

                          <td>{item?.loyaltyProgram?.description} </td>
                          <td>
                            {moment(item?.loyaltyProgram?.createdAt).format(
                              "ll"
                            )}
                          </td>
                          <td className="text-center">
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="info light"
                                className="light sharp btn btn-info i-false"
                              >
                                {svg1}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    props.history.push({
                                      pathname: "/user-loyalty-offers",
                                      state: {
                                        userId: userData?.id,
                                        programId: item?.loyaltyProgram?.id,
                                      },
                                    })
                                  }
                                >
                                  View Offers
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {data?.total === 0 && !loader && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {/* <div className="d-flex justify-content-end">
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div> */}
              </div>
            </Tab>

            <Tab eventKey="coupons" title="Purchased Coupons">
              <div className="text-black">
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>image</strong>
                      </th>
                      <th>
                        <strong>title</strong>
                      </th>
                      <th>
                        <strong>points</strong>
                      </th>
                      <th className="text-center">
                        <strong>Tier</strong>
                      </th>
                      <th>
                        <strong>Times per user</strong>
                      </th>

                      <th className="text-center">
                        <strong>Available offer count</strong>
                      </th>
                      <th>
                        <strong>Type</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Created At</strong>
                      </th>
                      <th>
                        <strong>Status</strong>
                      </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserTableSkeleton count={8} />
                  ) : (
                    <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                      {couponData?.coupons?.map((item, i) => (
                        <tr key={i}>
                          <td>
                            {" "}
                            <img
                              src={`https://api.paybagapp.com/${item?.coupon?.logo}`}
                              width={60}
                              height={60}
                              style={{ borderRadius: "20px" }}
                            />
                          </td>

                          <td>{item?.coupon?.title} </td>
                          <td className="text-center">{item?.points}</td>
                          <td className="text-center">{item?.coupon?.tier}</td>
                          <td className="text-center">
                            {item?.coupon?.oncePerUser}
                          </td>

                          <td className="text-center">
                            {item?.coupon?.availableNumber}
                          </td>
                          <td className="text-center">
                            {item?.coupon?.pointNeeded}
                          </td>
                          <td>{item?.coupon?.description} </td>
                          <td>
                            {moment(item?.coupon?.createdAt).format("ll")}
                          </td>

                          <td>
                            {item?.isRedeem ? (
                              <Badge
                                variant="danger light"
                                className="d-flex align-items-center "
                              >
                                {"Redeemed"}
                              </Badge>
                            ) : (
                              <Badge variant="success light" className="">
                                {"Active"}
                              </Badge>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {couponData?.total === 0 && !loader && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Total Coupons
                    <span
                      style={{
                        color: "#042e77",
                        border: "1px solid #042e77",
                        padding: "5px 15px",
                        borderRadius: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {couponData?.total}
                    </span>
                  </div>
                  <Pagination
                    pageCount={couponPageCount}
                    pageValue={currentCouponPage}
                    setPage={setCouponCurrentPage}
                  />
                </div>
              </div>
            </Tab>
            <Tab eventKey="pointHistory" title="Points History">
              <div className="text-black">
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>Points</strong>
                      </th>
                      <th>
                        <strong>Description</strong>
                      </th>
                      <th>
                        <strong>Created At</strong>
                      </th>
                      <th className="text-center">
                        <strong>Status</strong>
                      </th>
                    </tr>
                  </thead>
                  {loader ? (
                    <UserTableSkeleton count={8} />
                  ) : (
                    <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                      {pointHistory?.points?.map((item, i) => (
                        <tr
                          key={i}
                          style={{ background: i % 2 === 0 ? "#e0e6fb" : "" }}
                        >
                          {item?.type === "Credit" ? (
                            <td className="green-word">+{item?.points} </td>
                          ) : (
                            <td className="red-word">-{item?.points} </td>
                          )}
                          <td>{item?.description} </td>
                          <td>{moment(item?.createdAt).format("LL")}</td>
                          {item.status === "Confirm" ? (
                            <td className="text-center">
                              <Badge variant="success light">Completed</Badge>
                            </td>
                          ) : (
                            <td className="text-center">
                              <Dropdown>
                                <Dropdown.Toggle
                                  disabled={
                                    item?.status === "Pending" ? false : true
                                  }
                                  style={{
                                    backgroundColor: "#f0f6fc",
                                    color: "#2781d5",
                                    fontWeight: "800",
                                    padding: "3px",
                                    border: "0px",
                                    fontSize: "14px",
                                  }}
                                >
                                  <Badge variant="info light">Pending</Badge>
                                </Dropdown.Toggle>
                                {item?.status === "Pending" && (
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={() => onAction(item.id)}
                                    >
                                      Confirm
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                )}
                              </Dropdown>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                {pointHistory?.total === 0 && !loader && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Total History
                    <span
                      style={{
                        color: "#042e77",
                        border: "1px solid #042e77",
                        padding: "5px 15px",
                        borderRadius: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {pointHistory?.total}
                    </span>
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </div>
  );
}

const mapStateToProps = (state) => ({});
export default connect(mapStateToProps)(UserDetails);
