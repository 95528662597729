import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import toast from "react-hot-toast";
import { getTierApi, setTierApi } from "../../services/User/UserService";

const SetTierModal = ({ show, handleClose, title }) => {
  const [formValues, setFormValues] = useState({
    silverPoints: "",
    goldPoints: "",
    platinumPoints: "",
    diamondPoints: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (!isNaN(value) || value === "") {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
    if (value.trim() !== "") {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }
  };

  function getTierData() {
    setLoader(true);
    getTierApi()
      .then((response) => {
        setFormValues({
          silverPoints: response.data.data.silverPoints || "",
          goldPoints: response.data.data.goldPoints || "",
          platinumPoints: response.data.data.platinumPoints || "",
          diamondPoints: response.data.data.diamondPoints || "",
        });
        setLoader(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    const silverPoints = parseFloat(formValues.silverPoints);
    const goldPoints = parseFloat(formValues.goldPoints);
    const platinumPoints = parseFloat(formValues.platinumPoints);
    const diamondPoints = parseFloat(formValues.diamondPoints);

    if (String(formValues.silverPoints).trim() === "") {
      errors.silverPoints = "This field is required";
    }
    if (String(formValues.goldPoints).trim() === "") {
      errors.goldPoints = "This field is required";
    }
    if (String(formValues.platinumPoints).trim() === "") {
      errors.platinumPoints = "This field is required";
    }
    if (String(formValues.diamondPoints).trim() === "") {
      errors.diamondPoints = "This field is required";
    }

    if (goldPoints <= silverPoints) {
      errors.goldPoints = "Gold points must be greater than silver points";
    }
    if (platinumPoints <= goldPoints) {
      errors.platinumPoints =
        "Platinum points must be greater than gold points";
    }
    if (diamondPoints <= platinumPoints) {
      errors.platinumPoints =
        "Diamond points must be greater than diamond points";
    }

    setFormErrors(errors);

    if (Object.keys(errors).length > 0) {
      setLoader(false);
      return;
    }

    setLoader(true);

    try {
      await setTierApi(formValues);
      toast.success("Tier added successfully");
      handleClose();
    } catch (e) {
      toast.error(e?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTierData();
  }, []);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit} className="set-tier-form">
            <Form.Group controlId="formSilver">
              <Form.Label>Silver</Form.Label>
              <Form.Control
                type="number"
                name="silverPoints"
                value={formValues.silverPoints}
                placeholder="0.00"
                onChange={handleChange}
                isInvalid={!!formErrors.silverPoints}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.silverPoints}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formGold">
              <Form.Label>Gold</Form.Label>
              <Form.Control
                type="number"
                name="goldPoints"
                placeholder="0.00"
                value={formValues.goldPoints}
                onChange={handleChange}
                isInvalid={!!formErrors.goldPoints}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.goldPoints}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formPlatinum">
              <Form.Label>Platinum</Form.Label>
              <Form.Control
                type="number"
                name="platinumPoints"
                placeholder="0.00"
                value={formValues.platinumPoints}
                onChange={handleChange}
                isInvalid={!!formErrors.platinumPoints}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.platinumPoints}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formDiamond">
              <Form.Label>Diamond</Form.Label>
              <Form.Control
                type="number"
                name="diamondPoints"
                placeholder="0.00"
                value={formValues.diamondPoints}
                onChange={handleChange}
                isInvalid={!!formErrors.diamondPoints}
              />
              <Form.Control.Feedback type="invalid">
                {formErrors.diamondPoints}
              </Form.Control.Feedback>
            </Form.Group>

            <Button className="w-100 btn-sm" variant="primary" type="submit">
              {loader ? <Spinner animation="border" size="sm" /> : "Submit"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SetTierModal;
