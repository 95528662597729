import React, { useEffect, useState } from "react";
import { Col, Card, Table, Spinner, Badge } from "react-bootstrap";
import moment from "moment";

import { getReferenceUserApi } from "../../services/User/UserService";
import toast from "react-hot-toast";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Pagination from "../common/Pagination";
import defaultImage from "../../images/emptyImg.jpg";

export default function ReferenceManagement({ location }) {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const userId = location?.state;

  const limit = 10;
  console.log(data);
  function handleError(e) {
    e.target.src = defaultImage;
  }

  function getTableData() {
    setLoader(true);
    getReferenceUserApi(userId, currentPage, limit)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        toast.error(error?.response?.data?.data);
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div>
      <div className="page-titles">
        <h4>Referred Users</h4>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/user-management">User Management</Link>
          </li>
          <li className="breadcrumb-item active">
            <Link>Referred Users</Link>
          </li>
        </ol>
      </div>
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>image</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th className="text-center">
                      <strong>Email</strong>
                    </th>
                    <th className="text-center">
                      <strong>contact</strong>
                    </th>
                    <th className="text-center">
                      <strong>points</strong>
                    </th>
                    <th className="text-center">
                      <strong>status</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.users?.map((user, userIndex) => (
                    <tr
                      key={userIndex}
                      className={user.isDeleted ? "disabled-row" : ""}
                    >
                      <td>
                        <img
                          src={`https://api.paybagapp.com/${user?.image}`}
                          width={50}
                          height={50}
                          style={{ borderRadius: "25px" }}
                          onError={handleError}
                        />
                      </td>
                      <td>{user.name}</td>
                      <td className="text-center">{user.email}</td>
                      <td className="text-center">
                        <span>+{user.countryCode} </span>
                        {user.phoneNumber}
                      </td>
                      <td className="text-center">{user?.generalPoint}</td>

                      <td className="text-center">
                        {user?.isDeleted ? (
                          <Badge variant="danger light">Deleted</Badge>
                        ) : (
                          <>
                            {user.isBlocked ? (
                              <Badge variant="danger light">Deactivate</Badge>
                            ) : (
                              <Badge variant="success light">Active</Badge>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {data?.total === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {data?.total !== 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total User
                  <span
                    style={{
                      color: "#042e77",
                      border: "1px solid #042e77",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.total}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
