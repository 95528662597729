import React, { useEffect, useState } from "react";
import { Col, Card, Table, Spinner } from "react-bootstrap";
import UserTableSkeleton from "../components/UserTableSkeleton";
import moment from "moment";
import { getWheelHistoryApi } from "../../services/Vendor Services/VendorService";
import PageTitle from "../layouts/PageTitle";

export default function SpinWheelHistory() {
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);

  function getTableData() {
    setLoader(true);
    getWheelHistoryApi()
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        // const total = response.data.data.total;
        // setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  useEffect(() => {
    getTableData();
  }, []);

  return (
    <div>
      <PageTitle activeMenu="Spin Wheel History" motherMenu="Spin Wheel " />
      <Col>
        <Card>
          <Card.Body>
            {loader ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <Spinner animation="border" />
              </div>
            ) : (
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>Prize</strong>
                    </th>
                    <th className="text-center">
                      <strong>Quantity</strong>
                    </th>
                    <th className="text-center">
                      <strong>created At</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {data?.map((item, i) => (
                    <tr key={i}>
                      <td>{item.prize}</td>
                      <td className="text-center">{item.quantity}</td>
                      <td className="text-center">
                        {moment(item.createdAt).format("ll")}{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {data?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}

            <></>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
