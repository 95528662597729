import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const UserTableSkeleton = ({ count }) => {
  const skeletonRows = [];
  for (let i = 0; i < count; i++) {
    skeletonRows.push(
      <tr key={i}>
        <td className="py-1">
          <Skeleton width={50} height={50} duration={0.8} />
        </td>
        <td>
          <Skeleton width={140} height={20} duration={0.8} />
        </td>
        <td>
          <Skeleton width={150} height={20} duration={0.8} />
        </td>
        <td>
          <Skeleton width={150} height={20} duration={0.8} />
        </td>
        <td>
          <Skeleton width={150} height={20} duration={0.8} />
        </td>
        <td>
          <Skeleton width={100} height={20} duration={0.8} />
        </td>
        <td>
          <Skeleton width={50} height={33} duration={0.8} />
        </td>
      </tr>
    );
  }

  return <tbody>{skeletonRows}</tbody>;
};

export default UserTableSkeleton;
