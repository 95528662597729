import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Button,
  Spinner,
} from "react-bootstrap";
import Pagination from "../common/Pagination";
import {
  getAllVendorApi,
  venderActionApi,
  deleteVenderApi,
  venderApproveApi,
} from "../../services/Vendor Services/VendorService";
import defaultImage from "../../images/emptyImg.jpg";
import UserTableSkeleton from "../components/UserTableSkeleton";
import Switch from "react-switch";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import RejectVendorMessageModal from "../modal/RejectVendorMessageModal";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import AddVendorModal from "../modal/AddVendorModal";

export default function PendingVendorList(props) {
  const [loader, setLoader] = useState(false);
  const [list, setList] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [addVendorModal, setAddVendorModal] = useState(false);
  const [search, setSearch] = useState("");

  const [data, setData] = useState();

  const limit = 10;

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 500),
    []
  );

  function getTableData() {
    setLoader(true);
    getAllVendorApi(currentPage, limit)
      .then((response) => {
        setList(response.data.data);
        setLoader(false);
        const total = response.data.data.total;
        setPageCount(Math.ceil(total / limit));
      })
      .catch((error) => {
        console.log(error, "error");
        setLoader(false);
      });
  }

  async function handleApproveVender(id, status) {
    setLoader(true);
    try {
      const response = await venderApproveApi(id, status, "");
      toast.success("Vendor Approved Successfully");
      getTableData();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  }

  function onSendVenderItem(item) {
    props.history.push({
      pathname: "./vender-details",
      state: item,
    });
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, search]);

  return (
    <div>
      <div className="">
        <h3 className="m-0 p-3">New Vendor Requests</h3>{" "}
      </div>
      <div className="d-flex justify-content-between align-items-center pl-3 pr-3">
        <div className="form-group" style={{ width: "35%" }}>
          <input
            type="text"
            name="table_search"
            className="form-control"
            placeholder="Search"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        <div>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => setAddVendorModal(true)}
          >
            Add Vendor
          </button>
        </div>
      </div>
      <Col>
        {loader ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <Spinner animation="border" />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>

                    <th>
                      <strong>Created at</strong>
                    </th>
                    <th className="text-center">
                      <strong>ACTION</strong>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {list?.vendors?.map((item, i) => (
                    <tr key={i}>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item?.name}
                      </td>
                      <td onClick={() => onSendVenderItem(item)}>
                        {item?.email}
                      </td>

                      <td onClick={() => onSendVenderItem(item)}>
                        {moment(item?.createdAt).format("ll")}
                      </td>

                      <td className="text-center">
                        <div
                          class="d-flex justify-content-center"
                          style={{ gap: "10px" }}
                        >
                          <button
                            type="button"
                            class="btn btn-danger btn-xs"
                            onClick={() => {
                              setRejectModal(true);
                              setData(item);
                            }}
                          >
                            Reject
                          </button>
                          <button
                            type="button"
                            class="btn btn-success btn-xs"
                            onClick={() =>
                              handleApproveVender(item.id, "Accepted")
                            }
                          >
                            Approve
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {list?.vendors?.length === 0 && !loader ? (
                <div className="justify-content-center d-flex my-5 ">
                  Sorry, Data Not Found!
                </div>
              ) : (
                ""
              )}
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Vendor
                  <span
                    style={{
                      color: "#042e77",
                      border: "1px solid #042e77",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {list?.total}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </Col>
      {deleteModal && (
        <ConfirmationModal
          show={deleteModal}
          onHide={() => setDeleteModal(false)}
          successMessage={"Deleted Vendor successfully"}
          api={deleteVenderApi}
          refresh={getTableData}
          content={"Are you sure you want to Delete this Vendor ?"}
          data={data}
        />
      )}
      {rejectModal && (
        <RejectVendorMessageModal
          show={rejectModal}
          onHide={() => setRejectModal(false)}
          data={data}
          refresh={getTableData}
        />
      )}
      {addVendorModal && (
        <AddVendorModal
          show={addVendorModal}
          onHide={() => setAddVendorModal(false)}
          refresh={getTableData}
        />
      )}
    </div>
  );
}
