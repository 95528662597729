import React, { useState } from "react";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { setPointsLimitApi } from "../../services/User/UserService";

function SetLimitAndUserPoints({ show, onHide, data, refresh }) {
  const [firstTime, setFirstTime] = useState(data?.minimumPoint);
  const [numberOfTimes, setNumberOfTimes] = useState(data?.maximumPoint);
  const [errors, setErrors] = useState({});
  const [loader, setLoader] = useState(false);

  const validate = () => {
    const newErrors = {};
    const minPoints = Number(firstTime);
    const maxPoints = Number(numberOfTimes);

    if (!minPoints || minPoints <= 0) {
      newErrors.firstTime = "Min Points must be greater than 0.";
    }
    if (!maxPoints || maxPoints <= 0) {
      newErrors.numberOfTimes = "Max Points must be greater than 0.";
    } else if (maxPoints <= minPoints) {
      newErrors.numberOfTimes = "Max Points must be greater than Min Points.";
    }

    return newErrors;
  };

  const handleChange = (field, value) => {
    if (field === "firstTime") {
      setFirstTime(value);
    } else if (field === "numberOfTimes") {
      setNumberOfTimes(value);
    }

    if (value > 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [field]: undefined }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setLoader(true);
      try {
        const postData = {
          minimumPoint: Number(firstTime),
          maximumPoint: Number(numberOfTimes),
        };
        const response = await setPointsLimitApi(postData);
        onHide();
        toast.success("Points limit set successfully.");
        refresh();
      } catch (err) {
        toast.error(
          err?.response?.data?.data
            ? err?.response?.data?.data
            : err?.response?.data?.message
        );
      } finally {
        setLoader(false);
      }
    }
  };

  return (
    <>
      <Modal show={show} onHide={onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>Set Points Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <div className="d-flex" style={{ gap: "10px" }}>
              <Form.Group controlId="formFirstTime" style={{ flexGrow: "1" }}>
                <Form.Label>Min(points)</Form.Label>
                <Form.Control
                  type="number"
                  value={firstTime}
                  placeholder="Min"
                  onChange={(e) => handleChange("firstTime", e.target.value)}
                  isInvalid={!!errors.firstTime}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstTime}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                controlId="formNumberOfTimes"
                style={{ flexGrow: "1" }}
              >
                <Form.Label>Max(points)</Form.Label>
                <Form.Control
                  type="number"
                  value={numberOfTimes}
                  placeholder="Max"
                  onChange={(e) =>
                    handleChange("numberOfTimes", e.target.value)
                  }
                  isInvalid={!!errors.numberOfTimes}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numberOfTimes}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <Button variant="primary" type="submit" className="w-100 btn-sm">
              {loader ? <Spinner animation="border" size="sm" /> : "Save"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SetLimitAndUserPoints;
