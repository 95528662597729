import React, { useEffect, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { postNotificationApi } from "../../services/User/UserService";

export default function NotificationManagements() {
  let errorsObj = { title: "", message: "", type: "" };
  const formRef = useRef(null);
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    message: "",
  });
  const [image, setImage] = useState([]);

  const [loader, setLoader] = useState(false);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (formData?.title?.trim() === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (formData?.message?.trim() === "") {
      errorObj.message = "Body is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    const data = new FormData();
    data.append("title", formData.title);
    data.append("description", formData.message);
    data.append("tier", formData.type);
    if (image) {
      data.append("image", image);
    }
    try {
      const response = await postNotificationApi(data);
      toast.success("Sent Successfully.");
      setFormData({ title: "", type: "", message: "", image: null });
      if (formRef.current) {
        formRef.current.reset();
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      toast.error(
        error?.response?.data?.data
          ? error?.response?.data?.data
          : error?.response?.data?.message
      );
    }
  };

  return (
    <div>
      <PageTitle activeMenu="Send Notification" motherMenu="Notification" />

      <div className="col-lg-8 col-md-7 pl-0">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>

          <form onSubmit={onSubmit} ref={formRef}>
            <div className="form-group mb-3">
              <label className="text-black font-w500">Image</label>
              <div className="contact-name">
                <input
                  type="file"
                  accept="image/*"
                  className="form-control"
                  name="image"
                  onChange={(e) => setImage(e.target.files[0])}
                  required={true}
                />
                <span className="validation-text"></span>
                {errors.image && (
                  <div className="text-danger fs-12">{errors.image}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title</strong>
              </label>
              <input
                type="text"
                className="form-control"
                name="title"
                value={formData.title}
                onChange={handleInput}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Description</strong>
              </label>
              <textarea
                rows={5}
                className="form-control"
                name="message"
                value={formData.message}
                onChange={handleInput}
                placeholder="Write something here.."
              />
              {errors.message && (
                <div className="text-danger fs-12">{errors.message}</div>
              )}
            </div>

            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Tier</strong>
              </label>
              <select
                className="form-control"
                name="type"
                value={formData.type}
                onChange={handleInput}
              >
                <option value={""} disabled>
                  Select Tier
                </option>

                <option value={"all"}>All</option>
                <option value={"silver"}>Silver</option>
                <option value={"gold"}>Gold</option>
                <option value={"platinum"}>Platinum</option>
                <option value={"diamond"}>Diamond</option>
              </select>

              {errors.type && (
                <div className="text-danger fs-12">{errors.type}</div>
              )}
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="btn btn-primary btn-block"
                disabled={loader}
              >
                {loader ? (
                  <>
                    <Spinner animation="border" size="sm" /> Send
                  </>
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
