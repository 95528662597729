import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import HtmlEditor from "../components/HtmlEditor";

export default function AddBrandModal({
  title,
  show,
  onHide,
  api,
  refresh,
  successMessage,
  selectedBrand,
}) {
  const [image, setImage] = useState(selectedBrand?.image || "");
  const [errors, setErrors] = useState({
    name: "",
    title: "",
    link: "",
    description: "",
    howToRedeem: "",
  });
  const [howToRedeem, setHowToRedeem] = useState(selectedBrand?.howToRedeem);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: selectedBrand?.name || "",
    title: selectedBrand?.title || "",
    description: selectedBrand?.description || "",
    link: selectedBrand?.link || "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  // Regular expression for URL validation
  const urlPattern =
    /^(https?:\/\/)?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,})(\/\S*)?$/i;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errors };

    if (formData.name.trim() === "") {
      errorObj.name = "Name is required";
      error = true;
    }
    if (formData.title.trim() === "") {
      errorObj.title = "Title is required";
      error = true;
    }
    if (formData.description.trim() === "") {
      errorObj.description = "Description is required";
      error = true;
    }
    if (formData.link.trim() === "") {
      errorObj.link = "Link is required";
      error = true;
    } else if (!urlPattern.test(formData.link.trim())) {
      errorObj.link = "Link is not valid";
      error = true;
    }
    if (howToRedeem.trim() === "") {
      errorObj.howToRedeem = "How to Redeem is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    setLoader(true);
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("title", formData.title);
      formDataToSend.append("description", formData.description);
      formDataToSend.append("link", formData.link);
      formDataToSend.append("image", image);
      formDataToSend.append("howToRedeem", howToRedeem);

      if (title !== "Add Brand") {
        formDataToSend.append("brandId", selectedBrand.id);
      }

      await api(formDataToSend);
      refresh();
      onHide();
      toast.success(successMessage);
    } catch (error) {
      toast.error(error?.response?.data?.data || "An error occurred");
    } finally {
      setLoader(false);
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered className="brand-modal">
      <div className="p-4">
        <h3 className="text-center">{title}</h3>
        <form onSubmit={handleSubmit}>
          <div className="m-0 mt-2">
            <label>Image:</label>
            <input
              type="file"
              id="image"
              accept=".jpg,.png,.jpeg"
              className="form-control"
              onChange={(e) => setImage(e.target.files[0])}
              required={title === "Edit Brand" ? false : true}
            />
            {selectedBrand?.image && (
              <span>
                {selectedBrand?.image?.length > 30
                  ? selectedBrand?.image.slice(0, 30) + ".."
                  : selectedBrand?.image}
              </span>
            )}
          </div>

          <div>
            <label className="m-0 mt-2">Title:</label>
            <input
              type="text"
              id="title"
              required
              className="form-control"
              placeholder="Enter title"
              value={formData.title}
              onChange={handleChange}
            />
            {errors.title && (
              <div className="text-danger fs-12">{errors.title}</div>
            )}
          </div>

          <div>
            <label className="m-0 mt-2">Description:</label>
            <textarea
              style={{ minHeight: "100px" }}
              id="description"
              className="form-control"
              placeholder="Enter description"
              required
              value={formData.description}
              onChange={handleChange}
            ></textarea>
            {errors.description && (
              <div className="text-danger fs-12">{errors.description}</div>
            )}
          </div>
          <div>
            <label className="m-0 mt-2">Name:</label>
            <input
              type="text"
              id="name"
              required
              className="form-control"
              placeholder="Enter name"
              value={formData.name}
              onChange={handleChange}
            />
            {errors.name && (
              <div className="text-danger fs-12">{errors.name}</div>
            )}
          </div>
          <div>
            <label className="m-0 mt-2">Link:</label>
            <input
              type="text"
              id="link"
              className="form-control"
              placeholder="Enter link"
              required
              value={formData.link}
              onChange={handleChange}
            />
            {errors.link && (
              <div className="text-danger fs-12">{errors.link}</div>
            )}
          </div>
          <div className="">
            {" "}
            <label className="m-0 mt-2">How to redeem:</label>
            <HtmlEditor setValue={setHowToRedeem} showValue={howToRedeem} />
            {errors.howToRedeem && (
              <div className="text-danger fs-12">{errors.howToRedeem}</div>
            )}
          </div>

          <div className="d-flex justify-content-end pt-3">
            <button type="submit" className="btn btn-sm btn-primary">
              {loader ? <Spinner animation="border" size="sm" /> : "Save"}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}
