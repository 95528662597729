import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { getStoreApi } from "../../services/Vendor Services/VendorService";
import {
  addCouponApi,
  editCouponApi,
} from "../../services/Vendor Services/VendorService";

function AddCoupon(props) {
  const { title, successMessage, selectedCoupon } = props?.location?.state;

  const [formData, setFormData] = useState({
    title: selectedCoupon?.title,
    description: selectedCoupon?.description,
    discountType: selectedCoupon?.discountType,
    validDate: selectedCoupon?.validDate,
    startDate: selectedCoupon?.startDate,
    pointPrice: selectedCoupon?.pointPrice,
    tier: selectedCoupon?.tier,
    availableNumber: selectedCoupon?.availableNumber,
    oncePerUser: selectedCoupon?.oncePerUser,
    showInHome: selectedCoupon?.showInHome,
    pointNeeded: selectedCoupon?.pointNeeded,
  });
  const [image, setImage] = useState(selectedCoupon?.logo);
  const [storeList, setStoreList] = useState([]);
  const [storeId, setStoreId] = useState(selectedCoupon?.store_id);
  const [errors, setErrors] = useState({
    logo: "",
    title: "",
    storeId: "",
    description: "",
    validDate: "",
    startDate: "",
    tier: "",
    pointPrice: "",
    discountType: "",
    availableNumber: "",
    oncePerUser: "",
  });
  const [loader, setLoader] = useState(false);

  async function getDropdownList() {
    setLoader(true);
    try {
      const response = await getStoreApi();
      setStoreList(response?.data?.data);
    } catch (e) {
      toast.error(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    const newErrors = { ...errors };
    newErrors[name] = "";

    setErrors(newErrors);
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const newErrors = { ...errors };

    if (storeId === undefined) {
      newErrors.storeId = "StoreId is Required";
      error = true;
    }
    if (formData?.title?.trim() === "") {
      newErrors.title = "title is Required";
      error = true;
    }
    if (formData?.tier?.trim() === "") {
      newErrors.tier = "Tier is Required";
      error = true;
    }
    if (image?.name?.trim() === "") {
      newErrors.logo = "logo is Required";
      error = true;
    }
    if (formData?.validDate?.trim() === "") {
      newErrors.validDate = "validDate is Required";
      error = true;
    }

    if (formData?.description?.trim() === "") {
      newErrors.description = "description is Required";
      error = true;
    }
    if (Number(formData?.oncePerUser) > Number(formData?.availableNumber)) {
      newErrors.oncePerUser =
        "Once Per User must be equal Number of offers available";
      error = true;
    }

    if (error) {
      setErrors(newErrors);
      return;
    }
    setLoader(true);
    if (title === "Edit Coupon") {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("title", formData.title);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("pointPrice", formData.pointPrice);
        formDataToSend.append("validDate", formData.validDate);
        formDataToSend.append("logo", image);
        formDataToSend.append("startDate", formData.startDate);
        formDataToSend.append("tier", formData.tier);
        // formDataToSend.append("discountType", formData.discountType);
        formDataToSend.append("availableNumber", formData.availableNumber);
        formDataToSend.append("oncePerUser", formData.oncePerUser);
        formDataToSend.append("showInHome", formData.showInHome);
        formDataToSend.append("pointNeeded", formData.pointNeeded);
        formDataToSend.append("couponId", selectedCoupon.id);
        const response = await editCouponApi(formDataToSend);
        props.history.push("/coupon-management");
        toast.success(successMessage);
      } catch (error) {
        toast.error(error?.response?.data?.data);
      } finally {
        setLoader(false);
      }
    } else {
      try {
        const formDataToSend = new FormData();
        formDataToSend.append("title", formData.title);
        formDataToSend.append("description", formData.description);
        formDataToSend.append("pointPrice", formData.pointPrice);
        formDataToSend.append("validDate", formData.validDate);
        formDataToSend.append("logo", image);
        formDataToSend.append("startDate", formData.startDate);
        formDataToSend.append("tier", formData.tier);
        // formDataToSend.append("discountType", formData.discountType);
        formDataToSend.append("availableNumber", formData.availableNumber);
        formDataToSend.append("showInHome", formData.showInHome);
        formDataToSend.append("oncePerUser", formData.oncePerUser);
        formDataToSend.append("pointNeeded", formData.pointNeeded);
        formDataToSend.append("storeId", storeId);

        const response = await addCouponApi(formDataToSend);
        props.history.push("/coupon-management");
        toast.success(successMessage);
      } catch (error) {
        toast.error(error?.response?.data?.data);
      } finally {
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    const formatDateToISO = (date) => {
      if (date) {
        const isoDate = new Date(date);
        return isoDate.toISOString().split("T")[0];
      }
      return date;
    };

    const formattedValidDate = formatDateToISO(formData.validDate);
    const formattedStartDate = formatDateToISO(formData.startDate);

    if (
      formattedValidDate !== formData.validDate ||
      formattedStartDate !== formData.startDate
    ) {
      setFormData((prevState) => ({
        ...prevState,
        validDate: formattedValidDate,
        startDate: formattedStartDate,
      }));
    }

    getDropdownList();
  }, [formData.validDate, formData.startDate]);

  return (
    <div>
      <div className="p-4">
        <h3>{title}</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Logo</label>
            <input
              type="file"
              accept=".jpg,.png,.jpeg"
              className="form-control"
              name="logo"
              onChange={(e) => setImage(e.target.files[0])}
            />
            {errors.logo && <span className="text-danger">{errors.logo}</span>}
            {selectedCoupon?.logo?.length > 0 && (
              <span>{selectedCoupon?.logo}</span>
            )}
          </div>

          <div className="form-group">
            <label>Select Store</label>
            <select
              className="form-control"
              value={storeId}
              onChange={(e) => {
                setStoreId(e.target.value);
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  storeId: "",
                }));
              }}
              disabled={title === "Edit Coupon" ? true : false}
            >
              <option value="">Select...</option>
              {storeList.map((option, index) => (
                <option key={index} value={option?.id}>
                  {option.storeName}
                </option>
              ))}
            </select>
            {errors.storeId && (
              <span className="text-danger">{errors.storeId}</span>
            )}
          </div>
          <div className="form-group">
            <label>Coupon Name</label>
            <input
              type="text"
              name="title"
              required
              className="form-control"
              placeholder="Enter Coupon name"
              value={formData.title}
              onChange={handleChange}
            />
            {errors.title && (
              <span className="text-danger">{errors.title}</span>
            )}
          </div>
          {/* <div className="form-group">
            <label className="m-0">Discount Type</label>
            <select
              className="form-control"
              name="discountType"
              value={formData.discountType}
              onChange={handleChange}
              required
            >
              <option value="">Select..</option>
              <option value="Percentage discount">Percentage discount</option>
              <option value="Fixed amount discount">
                Fixed amount discount
              </option>
            </select>
            {errors.discountType && (
              <div className="text-danger fs-12">{errors.discountType}</div>
            )}
          </div> */}

          <div className="form-group">
            <label className="m-0">Tier</label>
            <select
              required
              className="form-control"
              name="tier"
              value={formData.tier}
              onChange={handleChange}
            >
              <option value="">Select a Tier</option>
              <option value="all">All</option>
              <option value="silver">Silver</option>
              <option value="gold">Gold</option>
              <option value="platinum">Platinum</option>
              <option value="diamond">Diamond</option>
            </select>
            {errors.tier && (
              <div className="text-danger fs-12">{errors.tier}</div>
            )}
          </div>

          <div className="form-group">
            <label>Number of offers available</label>
            <input
              type="number"
              name="availableNumber"
              className="form-control"
              placeholder="Enter Number"
              value={formData.availableNumber}
              onChange={handleChange}
              min={1}
              required
            />
            {errors.availableNumber && (
              <span className="text-danger">{errors.availableNumber}</span>
            )}
          </div>

          <div className="form-group">
            <label className="m-0">Points Needed</label>
            <select
              className="form-control"
              name="pointNeeded"
              value={formData.pointNeeded}
              onChange={handleChange}
              required
            >
              <option value="">Select..</option>
              <option value="Debit">Spend</option>
              <option value="Credit">Earn</option>
            </select>
          </div>
          <div
            className="d-flex align-items-center justify-content-center w-100"
            style={{ gap: "10px" }}
          >
            <div className="form-group" style={{ flexGrow: "1" }}>
              <label className="m-0">Once per user</label>
              <input
                type="number"
                name="oncePerUser"
                className="form-control"
                placeholder="Enter number"
                min={1}
                required
                value={formData.oncePerUser}
                onChange={handleChange}
              />
              {errors.oncePerUser && (
                <span className="text-danger">{errors.oncePerUser}</span>
              )}
            </div>
            <div className="form-group" style={{ flexGrow: "1" }}>
              <label className="m-0">Point Price</label>
              <input
                type="number"
                name="pointPrice"
                className="form-control"
                placeholder="Enter point price"
                required
                value={formData.pointPrice}
                onChange={handleChange}
              />
              {errors.pointPrice && (
                <span className="text-danger">{errors.pointPrice}</span>
              )}
            </div>
          </div>
          <div className="d-flex w-100" style={{ gap: "10px" }}>
            <div className="form-group" style={{ flexGrow: "1" }}>
              <label>Start Date</label>
              <input
                type="date"
                name="startDate"
                required
                className="form-control"
                value={formData.startDate}
                onChange={handleChange}
                min={getTodayDate}
              />
              {errors.startDate && (
                <span className="text-danger">{errors.startDate}</span>
              )}
            </div>
            <div className="form-group" style={{ flexGrow: "1" }}>
              <label>Valid Date</label>
              <input
                type="date"
                name="validDate"
                required
                className="form-control"
                value={formData.validDate}
                onChange={handleChange}
              />
              {errors.validDate && (
                <span className="text-danger">{errors.validDate}</span>
              )}
            </div>
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              name="description"
              value={formData.description}
              required
              className="form-control"
              placeholder="Description..."
              style={{ minHeight: "100px" }}
              onChange={handleChange}
            ></textarea>
            {errors.description && (
              <span className="text-danger">{errors.description}</span>
            )}
          </div>
          <div className="form-group">
            <label className="m-0">Show in home</label>
            <select
              className="form-control"
              name="showInHome"
              value={formData.showInHome}
              onChange={handleChange}
              required
            >
              <option value="">Select..</option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </div>
          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary btn-sm ">
              {loader ? <Spinner animation="border" size="sm" /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddCoupon;
