import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function HtmlEditor({ setValue, showValue }) {
  const handleChangeClinical = (content) => {
    setValue(content);
  };

  return (
    <div>
      <Editor
        apiKey="vurzvkgq1arl9j83l1910dxu02h5tculb0q9ygmaoxddaxx1"
        init={{
          height: 200,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code textcolor wordcount",
            "textarea",
            "textcolor",
            "forecolor backcolor",
          ],
          toolbar:
            "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
          content_style: "body { color: #000 }",
        }}
        onEditorChange={handleChangeClinical}
        name="prescription"
        value={showValue}
      />
    </div>
  );
}
