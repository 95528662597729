import React, { useEffect, useState } from "react";
import CutleryIcon from "../../images/cutlery 2.svg";
import moment from "moment";
import toast from "react-hot-toast";
import { Card, Spinner, Table } from "react-bootstrap";
import { getLoyaltyOffersApi } from "../../services/Vendor Services/VendorService";
import Pagination from "../common/Pagination";
import UserTableSkeleton from "../components/UserTableSkeleton";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function LoyaltyProgramOffer(props) {
  const data = props?.location?.state;
  const [offersList, setOfferList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 10;

  async function getOffersListing() {
    setLoader(true);
    try {
      const response = await getLoyaltyOffersApi(
        data?.storeId,
        data?.programId,
        currentPage,
        limit
      );
      setOfferList(response?.data?.data);
      const total = response.data.data.total;
      setPageCount(Math.ceil(total / limit));
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    getOffersListing();
  }, [data, currentPage]);

  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="">
            <div className="page-titles">
              <h4>Loyalty Offers</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/loyalty-programs">Loyalty Programs</Link>
                </li>
                <li className="breadcrumb-item active">
                  <Link>Loyalty Offers</Link>
                </li>
              </ol>
            </div>
          </div>
          <Card>
            <Card.Body>
              <Table responsive>
                <thead style={{ color: "black" }}>
                  <tr>
                    <th>
                      <strong>title</strong>
                    </th>
                    <th>
                      <strong>Description</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>points</strong>
                    </th>
                    <th className="text-center">
                      <strong>Tier</strong>
                    </th>
                    <th>
                      <strong>Times per user</strong>
                    </th>

                    <th>
                      <strong>valid from</strong>
                    </th>

                    <th>
                      <strong>valid till</strong>
                    </th>
                  </tr>
                </thead>
                {loader ? (
                  <UserTableSkeleton count={8} />
                ) : (
                  <tbody style={{ maxHeight: "420px", overflow: "scroll" }}>
                    {offersList?.offers?.map((item, i) => (
                      <tr
                        key={i}
                        className={item.isDeleted ? "disabled-row" : ""}
                      >
                        <td>{item?.title} </td>
                        <td>{item?.description} </td>
                        <td className="text-center">{item?.pointNeeded}</td>
                        <td className="text-center">{item?.pointPrice}</td>
                        <td className="text-center">{item?.tier}</td>
                        <td className="text-center">{item?.oncePerUser}</td>
                        <td> {moment(item?.startDate).format("ll")}</td>
                        <td>{moment(item?.validDate).format("ll")}</td>{" "}
                      </tr>
                    ))}
                  </tbody>
                )}
              </Table>

              {offersList.total === 0 && !loader && (
                <div className="d-flex justify-content-center">
                  <h3>Sorry no data found!</h3>
                </div>
              )}
              {offersList.total !== 0 && (
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="dataTables_info">
                    Total Offers
                    <span
                      style={{
                        color: "#042e77",
                        border: "1px solid #042e77",
                        padding: "5px 15px",
                        borderRadius: "5px",
                        marginLeft: "5px",
                      }}
                    >
                      {offersList?.total}
                    </span>
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default LoyaltyProgramOffer;
