export const GET_USERS = "admin/users";

export const DELETE_USERS = "admin/deleteUser";

export const BLOCK_USERS = "admin/userAction";

export const APPROVE_USER_DETAILS = "admin/auth/userApproved";

export const POST_USER_DETAILS = "admin/auth/signupUser";

export const GET_USER_PROGRAM = "admin/loyaltyProgramsList?";
export const GET_USER_REFERENCE = "admin/loyaltyProgramsList";
export const GET_VENDOR_EMPLOYEE = "admin/employeeList?";
export const GET_LOYALTY_PROGRAM = "admin/allLoyaltyProgramsList?";
