import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faTimes,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import "./EmailPopup.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Lottie from "lottie-react";
import animationData from "../../images/gmail.json";

function EmailPopup({ show, onClose }) {
  const history = useHistory();

  function handleOnClose() {
    onClose();
    history.push("/pending-vendors");
  }

  console.log(show, "jendjenjn");

  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={handleOnClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <div className="popup-header">
          <Lottie animationData={animationData} className="email-icon" />

          <h2>Check Your Email</h2>
        </div>
        <div className="popup-message">
          <p>
            A verification email has been sent to your inbox. Please check your
            email and follow the instructions to verify your account.
          </p>
        </div>
        <div className="ok-button">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => handleOnClose()}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}

export default EmailPopup;
