import React, { useEffect, useState } from "react";
import { Badge, Card, Spinner, Table } from "react-bootstrap";
import ShowImage from "../modal/ShowImage";
import pdfIcon from "../../images/pdf (1).png";
import toast from "react-hot-toast";
import { getVendorDetailsApi } from "../../services/Vendor Services/VendorService";
import { GET_VENDOR_EMPLOYEE } from "../../services/User/UserApiEndPoints";
import useFetch from "../components/CoustomHooks/useFetch";
import moment from "moment";
import Pagination from "../common/Pagination";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function VendorDetails(props) {
  const vendorData = props?.location?.state;
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [item, setItem] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loyaltyProgram, setLoyaltyProgram] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const limit = 10;

  const { data, error, loading } = useFetch(
    GET_VENDOR_EMPLOYEE,
    vendorData?.id,
    currentPage,
    limit
  );

  function prevImage(imageUrl) {
    setShowImage(true);
    setSelectedImage(imageUrl);
  }

  async function getVendorDetails() {
    setLoader(true);
    try {
      const response = await getVendorDetailsApi(vendorData?.id);
      setItem(response?.data?.data?.vendor);
      setLoyaltyProgram(response?.data?.data?.loyaltyProgram);
    } catch (err) {
      toast.error(err?.response?.data?.data);
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    getVendorDetails();
  }, [vendorData]);

  console.log(loyaltyProgram);
  return (
    <>
      {loader ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <div>
          <div className="page-titles">
            <h4>Vendor Details</h4>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/vendor-management">Vendor Management</Link>
              </li>
              <li className="breadcrumb-item active">
                <Link>Vendor Details</Link>
              </li>
            </ol>
          </div>
          <Card>
            <Card.Header className="d-block">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ gap: "1rem" }}
              >
                <div className="text-left" style={{ flex: "1" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Name</h5>
                    <p className="m-0">{item?.name}</p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Email</h5>
                    <p className="m-0">{item?.email}</p>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <h5 style={{ margin: "0" }}>Phone</h5>
                    <p style={{ margin: "0" }}>
                      +{item?.countryCode || ""} {item?.phoneNumber || "N/A"}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="m-0">Created At</h5>
                    <p className="m-0">
                      {moment(item?.createdAt).format("ll")}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-start ">
                    <h5 style={{ margin: "0" }}>Documents</h5>
                    {item?.proofOfOwnerShip?.endsWith(".pdf") ? (
                      <a
                        href={`${process.env.REACT_APP_API_BASE_URL}${item?.proofOfOwnerShip}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className=" pointer p-2"
                          src={pdfIcon}
                          height={50}
                          width={50}
                        />
                      </a>
                    ) : (
                      <img
                        src={`${process.env.REACT_APP_API_BASE_URL}${item?.proofOfOwnerShip}`}
                        onClick={() => prevImage(item?.proofOfOwnerShip)}
                        height={50}
                        width={50}
                        className="pointer"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Card.Header>
          </Card>
          <h5 className="pl-1">Stores Details</h5>
          {item?.stores[0] ? (
            <Card className="store-card">
              <div className="card-item">
                <div className="store-img">
                  <img
                    src={`https://api.paybagapp.com/${item?.stores[0]?.image}`}
                  />
                </div>
                <div className="card-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0">Name</p>
                    <p className="m-0">{item?.stores[0]?.storeName}</p>
                  </div>
                  <div className="d-flex justify-content-between ">
                    <p>Open at </p>
                    <p className="m-0">
                      {moment(item?.stores[0]?.openTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Close at </p>
                    <p className="m-0">
                      {moment(item?.stores[0]?.closeTime, "HH:mm:ss").format(
                        "hh:mm A"
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Contact </p>
                    <p>
                      <span>{item?.stores[0]?.countryCode || ""} </span>
                      {item?.stores[0]?.contactNumber || "N/A"}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Status </p>
                    <Badge variant="success light" className="mt-1">
                      {item?.stores[0]?.verifyStatus}
                    </Badge>
                  </div>
                </div>
              </div>
              <div className="store-details d-flex justify-content-between ">
                <p className="m-0">Address:</p>
                <p className="m-0" style={{ maxWidth: "60%" }}>
                  {item?.stores[0]?.address}
                </p>
              </div>
              <div className="store-details d-flex justify-content-between ">
                <p>Description:</p>
                <p style={{ maxWidth: "60%" }}>
                  {item?.stores[0]?.description}
                </p>
              </div>
            </Card>
          ) : (
            <div style={{ height: "100px" }}>
              <h4 className="text-center m-0">Sorry data not found!</h4>
            </div>
          )}

          <h5 className="pl-1">Loyalty Program Details</h5>

          {loyaltyProgram ? (
            <Card className="store-card ">
              <Card.Body className="p-0">
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>name</strong>
                      </th>
                      <th>
                        <strong>title</strong>
                      </th>
                      <th>
                        <strong>description</strong>
                      </th>
                      <th>
                        <strong>status</strong>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {loyaltyProgram?.map((item, i) => (
                      <tr
                        key={i}
                        className={item.isDeleted ? "disabled-row" : ""}
                        onClick={() =>
                          props.history.push({
                            pathname: "./loyalty-offers",
                            state: {
                              storeId: item?.store_id,
                              programId: item?.id,
                            },
                          })
                        }
                      >
                        <td>{item?.name}</td>
                        <td>{item?.title}</td>
                        <td>{item?.description}</td>
                        <td>
                          {item?.isDeleted ? (
                            <Badge variant="danger light">Deleted</Badge>
                          ) : (
                            <Badge variant="success light">Active</Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            <div style={{ height: "100px" }}>
              <h4 className="text-center m-0">Sorry data not found!</h4>
            </div>
          )}

          <Card>
            <Card.Header
              className="pb-3 "
              style={{ color: "black", fontWeight: "500" }}
            >
              Vendor Employees
            </Card.Header>
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <Card.Body className="pt-0 pb-0">
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>name</strong>
                      </th>
                      <th>
                        <strong>email</strong>
                      </th>
                      <th>
                        <strong>created At</strong>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {data?.employees?.map((item, i) => (
                      <tr key={i}>
                        <td>{item?.name}</td>

                        <td>{item?.email}</td>
                        <td>{moment(item?.createdAt).format("ll")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            )}
            {data?.total === 0 && !loading && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {data?.total !== 0 && !loading && (
              <div className="d-flex justify-content-between align-items-center p-3 pl-4">
                <div className="dataTables_info">
                  Total Employees
                  <span
                    style={{
                      color: "#042e77",
                      border: "1px solid #042e77",
                      padding: "5px 15px",
                      borderRadius: "5px",
                      marginLeft: "5px",
                    }}
                  >
                    {data?.total}
                  </span>
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            )}
          </Card>
          <ShowImage
            show={showImage}
            onHide={() => setShowImage(false)}
            image={selectedImage}
          />
        </div>
      )}
    </>
  );
}
