import React from "react";
import { Modal } from "react-bootstrap";

export default function ReferFriendCondition({ show, onHide }) {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <h4 className="m-0">Refer Friend Conditions</h4>
      </Modal.Header>
      <div className="pt-2 pl-4 pr-4 pb-4">
        <p>
          In this referral program, when an existing user (Alice) refers a
          friend (Bob) to join the platform, Alice will only earn 5 points if
          she already has at least 5 points herself. If Alice meets this
          requirement, she receives 5 points immediately after Bob successfully
          signs up using her referral link. Bob, the new user, can also earn a
          5-point welcome bonus, but only after he accumulates 5 points through
          regular platform activities like making purchases or engaging with
          content. Once Bob reaches this threshold, he receives his 5-point
          bonus, completing the referral cycle and rewarding both users for
          their participation.All points are fictional and should be editable
          from the admin panel.
        </p>
      </div>
    </Modal>
  );
}
